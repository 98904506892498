// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-templates-api-reference-api-reference-template-js": () => import("./../../../src/templates/apiReference/apiReferenceTemplate.js" /* webpackChunkName: "component---src-templates-api-reference-api-reference-template-js" */),
  "component---src-templates-group-template-js": () => import("./../../../src/templates/groupTemplate.js" /* webpackChunkName: "component---src-templates-group-template-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdownTemplate.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */)
}

